<template>
  <div class="orders-info">
    <van-nav-bar title="我的订单" left-arrow fixed @click-left="goBack" />
    <div class="main-content" :class="{'maginTop': true}">
      <van-tabs v-model="selectTab" sticky @change="onTabChange">
        <van-tab title="全部">
          <div class="divider"></div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="order-item"
              @click="onGetDetail(item.orderNo)"
            >
              <div class="order-header">
                <div class="header-left">{{item.productName}}</div>
                <div class="header-right">{{getStatusText(item.status)}}</div>
              </div>
              <div class="order-content">
                <img :src="item.mainImg" class="order-img" />
                <div class="order-text"></div>
              </div>
              <div class="gap"></div>
              <div class="order-bottom">
                <div class="left-count">共{{item.quantity}}件商品 实付款：￥{{item.payAmt}}</div>
                <button class="order-btn" @click.stop="onRepurchase(item.productId)">再次购买</button>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="未发货">
          <div class="divider"></div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="order-item"
              @click="onGetDetail(item.orderNo)"
            >
              <div class="order-header">
                <div class="header-left">{{item.productName}}</div>
                <div class="header-right">{{getStatusText(item.status)}}</div>
              </div>
              <div class="order-content">
                <img :src="item.mainImg" class="order-img" />
                <div class="order-text"></div>
              </div>
              <div class="gap"></div>
              <div class="order-bottom">
                <div class="left-count">共{{item.quantity}}件商品 实付款：￥{{item.payAmt}}</div>
                <button class="order-btn" @click.stop="onRepurchase(item.productId)">再次购买</button>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="已发货">
          <div class="divider"></div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="order-item"
              @click="onGetDetail(item.orderNo)"
            >
              <div class="order-header">
                <div class="header-left">{{item.productName}}</div>
                <div class="header-right">{{getStatusText(item.status)}}</div>
              </div>
              <div class="order-content">
                <img :src="item.mainImg" class="order-img" />
                <div class="order-text"></div>
              </div>
              <div class="gap"></div>
              <div class="order-bottom">
                <div class="left-count">共{{item.quantity}}件商品 实付款：￥{{item.payAmt}}</div>
                <button class="order-btn" @click.stop="onRepurchase(item.productId)">再次购买</button>
              </div>
            </div>
          </van-list>
        </van-tab>
        <van-tab title="已完成">
          <div class="divider"></div>
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="order-item"
              @click="onGetDetail(item.orderNo)"
            >
              <div class="order-header">
                <div class="header-left">{{item.productName}}</div>
                <div class="header-right">{{getStatusText(item.status)}}</div>
              </div>
              <div class="order-content">
                <img :src="item.mainImg" class="order-img" />
                <div class="order-text"></div>
              </div>
              <div class="gap"></div>
              <div class="order-bottom">
                <div class="left-count">共{{item.quantity}}件商品 实付款：￥{{item.payAmt}}</div>
                <button class="order-btn" @click.stop="onRepurchase(item.productId)">再次购买</button>
              </div>
            </div>
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { orderList } from "@/api/orders";
import { goNavBack } from "@/common/utils";
export default {
  name: "Orders",
  data() {
    return {
      selectTab: "",
      list: [],
      loading: false,
      finished: false,
      pageNum: 1,
      pageSize: 10,
      pages: 0,
      total: 0,
      statusTypes: [
        { value: -1, text: "全部" },
        { value: 2, text: "未发货" },
        { value: 3, text: "已发货" },
        { value: 4, text: "完成" }
      ]
    };
  },
  beforeRouteLeave(to, from, next) {
    // window.$tempSelectTab = this.selectTab;
    next();
  },
  created() {
    // if (this.list.length === 0) {
    //   this.onLoad();
    // }
    this.onLoad();
    if (this.pageNum > 1) {
      this.pageNum = 1;
    }
  },
  methods: {
    onLoad() {
      const timer = setTimeout(() => {
        // 若加载条到了底部
        this.getData(this.statusTypes[this.selectTab].value); // 调用上面方法,请求数据
        this.pageNum++; // 分页数加一
        this.finished && clearTimeout(timer); // 清除计时器
      }, 100);
      console.log("this.list =", this.list.length);
    },
    async getData(status) {
      const { data } = await orderList(status, this.pageNum, this.pageSize);
      if (data.records === 0) {
        this.list = []; // 清空数组
        this.finished = true; // 停止加载
      }
      this.total = data.total;
      this.list.push(...data.records);
      this.loading = false;
      if (this.list.length >= data.total) {
        this.finished = true;
      }
    },
    onTabChange() {
      console.log("onTabChange", this.selectTab);
      this.list = [];
      this.finished = false;
      this.loading = true;
      this.pageNum = 0;
      this.onLoad();
    },
    getStatusText(status) {
      if (status === 1) return "未支付";
      else if (status === 2) return "未发货";
      else if (status === 3) return "已发货";
      else if (status === 4) return "完成";
      else return "未知";
    },
    onRepurchase(productId) {
      console.log("productId = ", productId);
      this.$router.push({
        path: "/mall/detail/" + productId
      });
    },
    onGetDetail(orderNo) {
      this.$router.push({
        path: "/mall/orderDetail",
        query: { orderNo: orderNo }
      });
    },
    goBack() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        // 判断iPhone|iPad|iPod|iOS
        goNavBack();
      } else if (/(Android)/i.test(navigator.userAgent)) {
        // 判断Android
        goNavBack();
      } else {
        this.$router.goBack();
      }
    }
  }
};
</script>

<style lang="scss">
.orders-info {
  .main-content {
    // padding-top: 46px;
    background: #f8f8f8;
  }
  .divider {
    height: 15px;
  }
  .order-item {
    background: #fff;
    margin-bottom: 15px;
    .order-header {
      display: flex;
      padding: 10px 15px;
      justify-content: space-between;
      .header-left {
        font-weight: bold;
      }
      .header-right {
        color: #fc6037;
      }
    }
    .gap {
      border-bottom: 1px solid #eee;
    }
    .order-content {
      display: flex;
      padding: 0 15px;
      // justify-content: space-between;
      align-items: center;
      .order-img {
        width: 100px;
        height: 100px;
      }
      .order-text {
        margin-left: 25px;
      }
      margin-bottom: 15px;
    }
    .order-bottom {
      display: flex;
      padding: 10px 15px;
      justify-content: space-between;
      align-items: center;
      .left-count {
        color: #666;
        font-size: 14px;
      }
      .order-btn {
        background: #fc6037;
        border: 0;
        border-radius: 5px;
        padding: 7px 12px;
        font-size: 14px;
        color: white;
      }
    }
  }
}
</style>